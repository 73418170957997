import "./index.css"
import React from "react"
import { Grid, TextField, Button, Typography, Link } from "@material-ui/core"
import { validateEmail, validatePassword } from "./validations"
import { Page } from "../session/session"
import { FormattedMessage } from "react-intl"

const LoginPage = ({onLogin, onPageChanged}) => {
    const [credentials, setCredentials] = React.useState({
        email: "",
        emailError: "",
        password: "",
        passwordError: ""
    })

    const _onChange = (prop) => (event) => {
        let error = prop + "Error"
        let errorMsg = ""
        if (prop === "email") { 
            errorMsg = validateEmail(event.target.value)
        } else if (prop === "password") {
            errorMsg = validatePassword(event.target.value)
        }
        setCredentials({...credentials, [prop]: event.target.value, [error]: errorMsg})
    }

    const _onLoginClick = () => {
        onLogin(credentials.email, credentials.password)
    }

    const _onSignupClick = () => {
        onPageChanged(Page.USER_REGISTER)
    }

    return (
        <Grid
            container spacing={2}
            direction="column"
            justify="space-around"
            alignItems="center"
        >
            <Grid item xs={12}>
                <TextField 
                    className="Field"
                    label={<FormattedMessage id="login.email"/>} 
                    variant="filled" 
                    value={credentials.email} 
                    onChange={_onChange("email")}
                    error={credentials.emailError !== ""}
                    helperText={credentials.emailError}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField 
                    className="Field"
                    label={<FormattedMessage id="login.password"/>} 
                    variant="filled" 
                    type="password" 
                    value={credentials.password} 
                    onChange={_onChange("password")}
                    error={credentials.passwordError !== ""}
                    helperText={credentials.passwordError}
                />
            </Grid>
            <Grid item xs="auto">
                <Typography variant="body1">
                    <Button 
                        className="Field" 
                        variant="contained" 
                        color="primary" 
                        size="large" 
                        onClick={_onLoginClick}>
                        {<FormattedMessage id="login.button"/>}
                    </Button>
                </Typography>
            </Grid>
            <Grid item xs="auto">
                <Typography variant="body1" gutterBottom>
                {<FormattedMessage id="login.info"/>}
                    <Link onClick={_onSignupClick}>    
                        {<FormattedMessage id="login.info.link"/>}
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default LoginPage