// General Email Regex (RFC 5322 Official Standard)
const EMAIL_VALIDATION_EXPRESSION = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// Typo validations for email addresses
const GMAIL_TYPO_EXPRESSION = /^(.+)@(?:(?:gnail|gail|gmai|gmial|gmaii|gmaill)\.[a-z.]+)|.+\.(?:cm|co[^m])$/
const HOTMAIL_TYPO_EXPRESSION = /^(.+)@(?:(?:hotmial|hoymail|hoymial|homail|homial|hotmai|hotmaill)\.[a-z.]+)|.+\.(?:cm|co[^m])$/
const YAHOO_TYPO_EXPRESSION = /^(.+)@(?:(?:yaho|yaoo|yaoho|yahooo|yaaho|yaahoo)\.[a-z.]+)|.+\.(?:cm|co[^m])$/
const LIVE_TYPO_EXPRESSION = /^(.+)@(?:(?:liv|livee|lie|lice|libe)\.[a-z.]+)|.+\.(?:cm|co[^m])(?:\.(?:pt|py))?$/
const SAPO_TYPO_EXPRESSION = /^(.+)@(?:(?:sap|saapo|sappo|sao)\.[a-z.]+)|.+\.(?:pt|py)$/

const TYPO_VALIDATIONS = [
    GMAIL_TYPO_EXPRESSION, "gmail.com",
    HOTMAIL_TYPO_EXPRESSION, "hotmail.com",
    YAHOO_TYPO_EXPRESSION, "yahoo.com",
    LIVE_TYPO_EXPRESSION, "live.com.pt",
    SAPO_TYPO_EXPRESSION, "sapo.pt"
]

// Password length validation
const PASSWORD_MIN_LENGTH = 8

// Telephone contact rules (Based on ITU E.164 standard and Portugal numbering plan)
const TEL_VALID_NUMBERS = /^(?:\+?351)?[29]\d{8}$/

const validateEmail = (email) => {
    let errorMsg = ""
    if (!EMAIL_VALIDATION_EXPRESSION.test(email)) {
        errorMsg = "Formato de email inválido"
    } else {
        let matches = null
        let i = 0
        for (; i < TYPO_VALIDATIONS.length; i += 2) {
            matches = TYPO_VALIDATIONS[i].exec(email)
            if (matches) {
                break;
            }
        }
        if (matches && matches[1]) {
            errorMsg = "Quis dizer \"" + matches[1] + "@" + TYPO_VALIDATIONS[i + 1] + "\"?"
        }
    }
    return errorMsg
}

const validatePassword = (password) => {
    if (password.length < PASSWORD_MIN_LENGTH) {
        return "Mínimo de 8 carácteres"
    }
    return ""
}

const validateRepeatPassword = (password, repeat) => {
    if (password !== repeat) {
        return "A password não é igual"
    }
    return ""
}

const validateTelephone = (tel) => {
    if (TEL_VALID_NUMBERS.test(tel)) {
        return ""
    }
    return "O número não é válido"
}

export {
    validateEmail,
    validatePassword,
    validateRepeatPassword,
    validateTelephone
}