import { Snackbar, IconButton } from "@material-ui/core"
import { Close } from "@material-ui/icons"

const SEVERITY_SUCCESS = 0
const SEVERITY_ERROR = 1
const AUTO_HIDE_MILLIS = 5000

const Alert = ({message, severity, alertCallback}) => {
    const _onClose = () => {
        alertCallback("", SEVERITY_SUCCESS)
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={message !== ""} 
            autoHideDuration={AUTO_HIDE_MILLIS}
            message={message}
            onClose={_onClose}
            action={
                <IconButton color="inherit" onClick={_onClose}>
                    <Close/>
                </IconButton>
            }
        />
    )
}

export {
    Alert,
    SEVERITY_ERROR,
    SEVERITY_SUCCESS
}