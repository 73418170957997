import Config from "../config"
import localePt from "./pt.json"
import { termsPt } from "./pt.terms.jsx"

const LOCALES = {
    "pt": {
        "texts": localePt,
        "terms": termsPt
    }
}
const LOCALE = navigator.language.split(/[-_]/)[0]
const OPTED_LOCALE = LOCALE in LOCALES ? LOCALE : Config.defaultLocale

console.debug("opted locale %s", OPTED_LOCALE)

const LocalizedTexts = () => {
    return LOCALES[OPTED_LOCALE]["texts"]
}

const LocalizedTerms = () => {
    return LOCALES[OPTED_LOCALE]["terms"]()
}

export {
    OPTED_LOCALE,
    LocalizedTexts,
    LocalizedTerms
}