import Communication from '../'




function signup(email, phoneNumber, password) {
  return Communication.client.request({
    url: 'auth/signup',
    method: 'POST',
    data: {
      email: email,
      phoneNumber: phoneNumber,
      password:password
    }
  })
}

function confirm_phone_number(code, email) {
  return Communication.client.request({
    url: 'auth/confirm-phone-number',
    method: 'POST',
    data: {
      code: code,
      email: email
    }
  })
}

export default {
  signup,
  confirm_phone_number
}
