const Page = {
    NONE: "",
    HOME: "/",
    USER_LOGIN: "/login",
    USER_REGISTER: "/signup",
    USER_CONFIRM: "/signup/confirm",
    USER_TERMS: "/signup/terms",
    USER_DETAILS: "/user",
    REQUEST_SHOWCASE: "/requests",
    REQUEST_CREATE: "/requests/create",
    REQUEST_VIEW: "/requests/view"
}

export {
    Page
}