import { Button, Card, CardActions, CardContent, Typography } from "@material-ui/core"
import { Page } from "../session/session"
import { FormattedMessage } from "react-intl"
import { LocalizedTerms } from "../../translations/locale"

const TermsPage = ({onPageChanged}) => {
    const _onReturnClick = () => {
        onPageChanged(Page.HOME)
    }

    return (
        <Card variant="elevation">
            <CardContent>
                <Typography variant="h3">
                    {<FormattedMessage id="terms.title"/>}
                </Typography>
                <LocalizedTerms/>
            </CardContent>
            <CardActions>
                <Button onClick={_onReturnClick}>
                    {<FormattedMessage id="terms.button"/>}
                </Button>
            </CardActions>
        </Card>
    )
}

export default TermsPage