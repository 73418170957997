import { AppBar, Toolbar, Typography } from '@material-ui/core'

const Header = ({onLogout, onPageChanged}) => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6">
                    Test-Header
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default Header