import "./index.css"
import React from "react"
import { Grid, TextField, Button, Typography, Checkbox, Link } from "@material-ui/core"
import { Favorite, FavoriteBorder } from "@material-ui/icons"
import { Page } from "../session/session"
import { validateEmail, validatePassword, validateRepeatPassword, validateTelephone } from "./validations"
import { FormattedMessage } from "react-intl"

const RegisterPage = ({onSigup, onPageChanged}) => {
    const [credentials, setCredentials] = React.useState({
        email: "",
        emailError: "",
        password: "",
        passwordError: "",
        repPassword: "",
        repPasswordError: "",
        telephone: "",
        telephoneError: "",
        acceptTerms: false,
        cannotRegister: false
    })

    const _cannotRegister = () => {
        return credentials.emailError !== "" || credentials.passwordError !== ""
            || credentials.repPasswordError !== "" || credentials.telephoneError !== ""
            || !credentials.acceptTerms
    }

    const _onSignupClicked = () => {
        onSigup(credentials.email, credentials.password, credentials.telephone)
    }

    const _onTermsClicked = () => {
        onPageChanged(Page.USER_TERMS)
    }

    const _onAcceptTermsClicked = () => {
        console.debug("terms accepted: %s", !credentials.acceptTerms ? "YES" : "NO")
        setCredentials({...credentials, acceptTerms: !credentials.acceptTerms, cannotRegister: _cannotRegister()})
    }

    const _onChange = (prop) => (event) => {
        let error = prop + "Error"
        let errorMsg = ""
        if (prop === "email") { 
            errorMsg = validateEmail(event.target.value)
        } else if (prop === "password") {
            errorMsg = validatePassword(event.target.value)
        } else if (prop === "repPassword") {
            errorMsg = validateRepeatPassword(credentials.password, event.target.value)
        } else if (prop === "telephone") {
            errorMsg = validateTelephone(event.target.value.replace(/\s/g, ""))
        }
        console.debug(credentials)
        setCredentials({...credentials, [prop]: event.target.value, [error]: errorMsg, cannotRegister: _cannotRegister()})
    }

    return (
        <Grid
            container spacing={2}
            direction="column"
            justify="space-around"
            alignItems="center"
        >
            <Grid item xs="auto">
                <TextField 
                    className="Field"
                    label={<FormattedMessage id="signup.email"/>}
                    variant="filled" 
                    value={credentials.email} 
                    onChange={_onChange("email")}
                    error={credentials.emailError !== ""}
                    helperText={credentials.emailError}
                />
            </Grid>
            <Grid item xs="auto">
                <TextField 
                    className="Field"
                    label={<FormattedMessage id="signup.telephone"/>}
                    variant="filled" 
                    value={credentials.telephone} 
                    onChange={_onChange("telephone")}
                    error={credentials.telephoneError !== ""}
                    helperText={credentials.telephoneError}
                />
            </Grid>
            <Grid item xs="auto">
                <TextField 
                    className="Field"
                    label={<FormattedMessage id="signup.password"/>} 
                    variant="filled" 
                    type="password" 
                    value={credentials.password} 
                    onChange={_onChange("password")}
                    error={credentials.passwordError !== ""}
                    helperText={credentials.passwordError}
                />
            </Grid>
            <Grid item xs="auto">
                <TextField 
                    className="Field"
                    label={<FormattedMessage id="signup.reppassword"/>} 
                    variant="filled" 
                    type="password" 
                    value={credentials.repPassword} 
                    onChange={_onChange("repPassword")}
                    error={credentials.repPasswordError !== ""}
                    helperText={credentials.repPasswordError}
                />
            </Grid>
            <Grid item xs="auto">
                <Grid container spacing={0}>
                    <Checkbox 
                        icon={<FavoriteBorder/>} 
                        checkedIcon={<Favorite/>} 
                        name="terms" 
                        onClick={_onAcceptTermsClicked}
                    />
                    <Typography className="LabelText" variant="body1" gutterBottom>
                    {<FormattedMessage id="signup.terms"/>}
                        <Link onClick={_onTermsClicked}>    
                            {<FormattedMessage id="signup.terms.link"/>}
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs="auto">
                <Button 
                    variant="contained" 
                    color="primary" 
                    size="large" 
                    onClick={_onSignupClicked}
                    disabled={_cannotRegister()}
                >
                    {<FormattedMessage id="signup.button"/>}
                </Button>
            </Grid>
        </Grid>
    )
}

export default RegisterPage