export const termsPt = () => {
    return (
        <div>
        <h4>Informa&ccedil;&atilde;o legal</h4>
        <p><br />O site&nbsp;D de dar&nbsp;proporciona aos utilizadores uma plataforma na internet com o objectivo de facilitar a doa&ccedil;&atilde;o e recebimento de bens atrav&eacute;s de um sistema de an&uacute;ncios.<br />&nbsp;<br />O registo e a coloca&ccedil;&atilde;o de an&uacute;ncios s&atilde;o inteiramente gratuitos.<br />&nbsp;<br />O site&nbsp;D de dar&nbsp;n&atilde;o tem acesso ou controlo sobre os artigos comunicados nos an&uacute;ncios nem se envolve ou participa na troca efectiva entre doador e benefici&aacute;rio, n&atilde;o garantindo, por isso, a qualidade, seguran&ccedil;a ou legalidade dos artigos, a veracidade ou exactid&atilde;o destes, a capacidade legal dos intervenientes, nem pode garantir que ambas as partes completem a entrega/recep&ccedil;&atilde;o.</p>
        <p>&nbsp;</p>
        <h4>Acesso ao site Dar e Receber</h4>
        <p><br />N&atilde;o tendo o site&nbsp;D de dar&nbsp;um conte&uacute;do uniforme, n&atilde;o &eacute; poss&iacute;vel restringir o acesso &agrave; sua utiliza&ccedil;&atilde;o somente de acordo com um crit&eacute;rio de maioridade.<br />&nbsp;<br />A legalidade do acesso &agrave; utiliza&ccedil;&atilde;o est&aacute; dependente do facto de n&atilde;o serem celebrados qualquer tipo de contratos, onerosos ou n&atilde;o, sob a al&ccedil;ada da plataforma, pelo que o acesso ao servi&ccedil;o &eacute; permitido aos utilizadores, independentemente da posse de total capacidade jur&iacute;dica, por parte destes. N&atilde;o detendo um car&aacute;cter mediador de transac&ccedil;&otilde;es, o site&nbsp;D de dar&nbsp;n&atilde;o se responsabiliza por qualquer eventualidade resultante da falta de capacidade jur&iacute;dica dos utilizadores.<br />&nbsp;<br />A qualquer interessado pode corresponder apenas um registo, podendo o site&nbsp;D de dar&nbsp;cancelar qualquer registo subsequente efectuado pelo mesmo indiv&iacute;duo.<br />&nbsp;<br />Os registos no site&nbsp;Da de dar&nbsp;s&atilde;o pessoais e intransmiss&iacute;veis, sendo o titular do mesmo o &uacute;nico respons&aacute;vel pelas ac&ccedil;&otilde;es efectuadas com o seu registo.</p>
        <p>&nbsp;</p>
        <h4>Termos gerais de utiliza&ccedil;&atilde;o</h4>
        <ol>
        <li>&nbsp;&Eacute; permitido colocar um an&uacute;ncio relacionado com um produto ou com a respectiva necessidade. Um mesmo an&uacute;ncio n&atilde;o pode ser objecto de mais do que um assunto a ser anunciado;</li>
        <li>&nbsp;An&uacute;ncios que contenham manipula&ccedil;&atilde;o de palavras ou que sejam atribu&iacute;dos a categorias inapropriadas, podem segundo estes termos de condi&ccedil;&otilde;es ser alvo de medidas que incluam a sua remo&ccedil;&atilde;o definitiva;</li>
        <li>O site D de dar n&atilde;o se responsabiliza pelo conte&uacute;do dos an&uacute;ncios;</li>
        <li>O site D de dar pode remover ou editar as descri&ccedil;&otilde;es colocadas num an&uacute;ncio pelos utilizadores, sem ter de referir a causa do mesmo;</li>
        <li>O site D de dar pode excluir o utilizador de usar o servi&ccedil;o (ex.: impedi-lo de fazer log in, de colocar ou responder a an&uacute;ncios), quando o utilizador tiver violado alguma das condi&ccedil;&otilde;es deste termo de uso, presentes neste documento;</li>
        <li>O site D de dar esfor&ccedil;a-se por operar correctamente sem falhar, mas no entanto, reserva o direito para suspender temporariamente as suas opera&ccedil;&otilde;es por raz&otilde;es t&eacute;cnicas, ou causas para al&eacute;m do controlo do site D de dar;</li>
        <li>O site D de dar n&atilde;o se responsabiliza pelo comportamento dos anunciantes, ou produtos e trabalho anunciados por eles como descrito nos seus an&uacute;ncios. Ao infringir num determinado an&uacute;ncio as regras vigentes no site D de dar, o utilizador assumir&aacute; toda e qualquer responsabilidade resultando em danos ou preju&iacute;zos perante qualquer entidade, pessoa singular ou colectiva, estando o site D de dar isento de qualquer tipo de responsabilidade da&iacute; resultante;</li>
        <li>O site D de dar n&atilde;o se responsabiliza por qualquer dano que ocorra na sequ&ecirc;ncia de uma transac&ccedil;&atilde;o, ou comportamento inadequado de uma das partes da transac&ccedil;&atilde;o.</li>
        </ol>
        <h4>&nbsp;</h4>
        <h4>Termo de utiliza&ccedil;&atilde;o respons&aacute;vel</h4>
        <p><br />De forma a garantir uma elevada qualidade a todos os utilizadores do site&nbsp;D de dar, prevenindo situa&ccedil;&otilde;es abusivas ou que coloquem em causa o seu desempenho, o site&nbsp;D de dar&nbsp;poder&aacute; aplicar uma pol&iacute;tica de utiliza&ccedil;&atilde;o respons&aacute;vel, limitando a coloca&ccedil;&atilde;o de novos an&uacute;ncios, a todos os utilizadores que abusem no n&uacute;mero de an&uacute;ncios.</p>
        <p>&nbsp;</p>
        <h4>Responsabilidade do utilizador</h4>
        <p><br />Ao aceitar as presentes Condi&ccedil;&otilde;es de Servi&ccedil;o, o utilizador obriga-se a:</p>
        <ol>
        <li>Fornecer e manter dados pessoais ver&iacute;dicos, completos e actualizados;</li>
        <li>Manter confidenciais os dados de acesso ao seu registo no site D de dar e usar unicamente o registo de que for titular, assumindo a plena responsabilidade pelas ac&ccedil;&otilde;es efectuadas com esse mesmo registo, ainda que por terceiros, com ou sem a sua autoriza&ccedil;&atilde;o;</li>
        <li>Adoptar unicamente comportamentos que n&atilde;o infrinjam a ordem jur&iacute;dica vigente ou que lesem, de alguma forma, posi&ccedil;&otilde;es juridicamente protegidas;</li>
        <li>N&atilde;o apresentar ofertas de produtos il&iacute;citos ou de car&aacute;cter violento, provocativo, ofensivo, racista ou suscept&iacute;vel de violar a privacidade ou direitos de terceiros;</li>
        <li>Cumprir todas as obriga&ccedil;&otilde;es decorrentes da transac&ccedil;&atilde;o de bens atrav&eacute;s dos seus an&uacute;ncios no site D de dar;</li>
        <li>Enquanto anunciante, assumir a total e exclusiva responsabilidade pela veracidade dos dados do an&uacute;ncio e a sua adequa&ccedil;&atilde;o ao conte&uacute;do do an&uacute;ncio, bem como a responsabilidade pela legalidade do artigo anunciado.</li>
        </ol>
        <p>&nbsp;</p>
        <h4>Conte&uacute;do e An&uacute;ncios</h4>
        <p><br />O site&nbsp;D de dar compromete-se a fazer uma pr&eacute; verifica&ccedil;&atilde;o ou aprova&ccedil;&atilde;o manual dos an&uacute;ncios colocados no site pelos utilizadores garantindo desta forma que os mesmos se encontram de acordo com as regras gerais de utiliza&ccedil;&atilde;o.</p>
        <p>&nbsp;</p>
        <h4>Pol&iacute;tica de Privacidade - Recolha de dados pessoais</h4>
        <p><br />O site&nbsp;D de dar&nbsp;exige que o utilizador passe pelo processo de login de forma a ter acesso &agrave;s &aacute;reas de an&uacute;ncio e de resposta aos an&uacute;ncios.&nbsp;<br />A recolha de dados de identifica&ccedil;&atilde;o do utilizador ser&aacute; efectuada atrav&eacute;s do preenchimento de um formul&aacute;rio de registo online e ocorrer&aacute; de acordo com as mais estritas regras de seguran&ccedil;a.<br />&nbsp;<br />O utilizador, ao registar-se no site&nbsp;D de dar, fornece dados pessoais, tais como o nome, endere&ccedil;o electr&oacute;nico, telefone, e-mail.&nbsp;<br />Os dados recolhidos s&atilde;o introduzidos no sistema inform&aacute;tico, onde s&atilde;o tratados e processados automaticamente nos termos aprovados pela Comiss&atilde;o Nacional de Protec&ccedil;&atilde;o de Dados (CNPD), destinando-se os dados &agrave; gest&atilde;o de servi&ccedil;os do site&nbsp;D de dar.</p>
        <p>&nbsp;</p>
        <h4>Seguran&ccedil;a e Qualidade da informa&ccedil;&atilde;o</h4>
        <p><br />&Eacute; nosso objectivo garantir a qualidade e integridade da informa&ccedil;&atilde;o fornecida pelos utilizadores do site&nbsp;Dar e Receber, tendo, neste sentido, sido implementadas as medidas necess&aacute;rias, tanto ao n&iacute;vel tecnol&oacute;gico como organizacional, de forma a mantermos a informa&ccedil;&atilde;o segura, precisa, actualizada e completa.</p>
        <p>&nbsp;</p>
        <h4>Partilha da informa&ccedil;&atilde;o pessoal</h4>
        <p><br />A informa&ccedil;&atilde;o pessoal recolhida &eacute; utilizada pelo site&nbsp;D de dar&nbsp;para possibilitar a presta&ccedil;&atilde;o do servi&ccedil;o de an&uacute;ncios online de forma a permitir a comunica&ccedil;&atilde;o das inten&ccedil;&otilde;es de doa&ccedil;&atilde;o de artigos.<br />&nbsp;<br />Al&eacute;m disso, o site&nbsp;D de dar&nbsp;poder&aacute; fornecer os dados pessoais &agrave;s entidades legais competentes nos casos em que tais dados forem solicitados por essas entidades na sequ&ecirc;ncia de investiga&ccedil;&otilde;es sobre actividades il&iacute;citas, fraudulentas, de infrac&ccedil;&atilde;o aos direitos de propriedade industrial ou intelectual.</p>
        </div>
    )
}
