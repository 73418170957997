import './App.css';
import React from 'react';
import Header from "./components/header/index"
import Footer from "./components/footer/index"
import { Page } from "./components/session/session"
import Auth from './communication/auth'
import { Alert, SEVERITY_SUCCESS, SEVERITY_ERROR } from "./components/alerts/alert"
import { FormattedMessage, IntlProvider } from 'react-intl';
import { OPTED_LOCALE, LocalizedTexts } from './translations/locale';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import LoginPage from './components/pages/login-page';
import RegisterPage from './components/pages/register-page';
import TermsPage from "./components/pages/terms-page";

const App = () => {
    const [state, setState] = React.useState({
        pageRedirect: Page.HOME,
        loggedIn: false,
        infoMessage: "",
        infoSeverity: SEVERITY_SUCCESS
    })

    const _signupCallback = async (user, password, phone) => {
        console.debug("signing up user %s, request confirmation", user)
        Auth.signup(user, phone, password).then((res) => {
            _alertSuccess(<FormattedMessage id="alert.success.signup"/>)
            console.debug(res)
            setState({...state, page: Page.USER_CONFIRM})
        }).catch((err) => {
            _alertError(<FormattedMessage id="alert.failed.signup"/>)
            console.error(err)
        })
    }

    const _loginCallback = async (user, password) => {
        console.debug("logged in to user %s", user)
        setState({...state, page: Page.HOME, loggedIn: true})
    }

    const _logoutCallback = async () => {
        console.debug("logged out from user")
        setState({...state, page: Page.HOME, loggedIn: false})
    }

    const _changePageCallback = (nextPage) => {
        console.debug("jump to page %s", nextPage)
        setState({...state, pageRedirect: nextPage})
    }

    const _alertError = (message) => {
        _alertCallback(message, SEVERITY_ERROR)
    }

    const _alertSuccess = (message) => {
        _alertCallback(message, SEVERITY_SUCCESS)
    }

    const _alertCallback = (message, severity) => {
        setState({
            ...state, 
            infoMessage: message, 
            infoSeverity: severity
        })
    }

    const RedirectPage = () => {
        if (state.pageRedirect !== Page.NONE) {
            return <Redirect to={state.pageRedirect}/>
        }
        return <></>
    }

    return ( 
        <IntlProvider locale={OPTED_LOCALE} messages={LocalizedTexts()}>
            <div className="App">
                <div className="Header">
                    <Header 
                    onLogout={_logoutCallback}
                    onPageChanged={_changePageCallback}
                    />
                </div>
                <div className="Page">
                    <BrowserRouter>
                        <Switch>
                            <Route path={Page.USER_TERMS}>
                                <TermsPage onPageChanged={_changePageCallback}/>
                            </Route>
                            <Route path={Page.USER_CONFIRM}>
                                <></>
                            </Route>
                            <Route path={Page.USER_REGISTER}>
                                <RegisterPage 
                                    onSigup={_signupCallback} 
                                    onPageChanged={_changePageCallback}
                                />
                            </Route>
                            <Route path={Page.USER_LOGIN}>
                                <LoginPage 
                                    onLogin={_loginCallback} 
                                    onPageChanged={_changePageCallback}
                                />
                            </Route>
                            <Route path={Page.HOME}>
                                {/* FIXME: For now, defaults HOME to the Login page */}
                                <LoginPage 
                                    onLogin={_loginCallback} 
                                    onPageChanged={_changePageCallback}
                                />
                            </Route>
                        </Switch>
                        {/* This div is to process page redirects, when requested by the callback */}
                        <div>
                            <RedirectPage/>
                        </div>
                    </BrowserRouter>
                </div>
                <div className="Footer">
                    {/* FIXME: Add Footer components */}
                </div>
                <div className="Alert">
                    <Alert 
                        message={state.infoMessage} 
                        severity={state.infoSeverity} 
                        alertCallback={_alertCallback}
                    />
                </div>
            </div>
        </IntlProvider>                
    )
}

export default App