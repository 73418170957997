import Config from '../config'
import Path from 'path'
import axios from 'axios'

const instance = axios.create({
  baseURL: Path.join(Config.apiBaseUrl, 'api')
})

export default {
  client: instance
}
